<template>
  <transition name="modal-fade">
    <div ref="modal" class="modal-backdrop" @click="clickOutside">
      <div class="modal" role="dialog">
        <div class="btn-close" @click="close">
          <md-icon>close</md-icon>
        </div>

        <h4>Просмотр баффа</h4>

        <div v-if="item" class="md-layout md-gutter">
          <div class="md-layout-item md-size-100 md-large-size-30">
            <md-field>
              <label for="md-input-custom">ID</label>
              <md-input disabled :value="item.buffId" />
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-large-size-70">
            <md-field>
              <label for="md-input-custom">Название</label>
              <md-input disabled :value="item.name" />
            </md-field>
          </div>

          <div class="md-layout-item md-size-100">
            <md-field>
              <label for="md-input-custom">Описание</label>
              <md-textarea disabled :value="item.description" />
            </md-field>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  methods: {
    clickOutside(e) {
      if (e.target.classList.contains("modal-backdrop")) {
        this.close();
      }
    },

    close(needUpdate = false) {
      this.$emit("close", needUpdate);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10;

  .modal {
    position: relative;
    height: auto;
    padding: 20px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
    background: #fff;
  }

  .btn-close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 20px;
    color: #c4c4c4;
  }
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
